@tailwind base;
@tailwind components;
@tailwind utilities;

.hollow-text-white {
  -webkit-text-stroke: 2px white; 
  color: transparent;
}

.hollow-text-black {
  -webkit-text-stroke: 2px black;
  color: transparent;
}

.gradient-button {
  background-image: linear-gradient(90deg, #832eff, #fe4ff2);
}

.gradient-button-reverse {
  background-image: linear-gradient(90deg, #fe4ff2, #832eff);
}

@font-face {
  font-family: 'TrendSansOne';
  src: url('../public/fonts/Latinotype\ -\ TrendSansOne.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaExtraCondensedBold';
  font-style: normal;
  font-weight: normal;
  src: url('../public//fonts/Mark\ Simonson\ \ Proxima\ Nova\ Condensed\ Bold\ TheFontsMaster.com.woff') format('woff');
}